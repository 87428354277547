@import "partials/responsive_mixins";

.imageWrapper {
  margin: 48px auto 40px;

  @include mobile-breakpoint {
    margin: 48px auto 24px;
  }
}

.content {
  margin: 0 20px;
  padding-bottom: 24px;
  text-align: center;

  h3 {
    font-size: 32px;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 24px;
  }

  a {
    margin-bottom: 18px;
  }

  button,
  a {
    max-width: 224px;
  }

  button {
    margin: 0 auto;
  }

  @include mobile-breakpoint {
    margin: 0 16px;

    h3 {
      font-size: 24px;
    }

    button,
    a {
      max-width: unset;
    }
  }
}
