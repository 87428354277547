@import "../../../../../stylesheets/sass/partials/responsive_mixins";

.modal {
  min-height: 100vh;

  .modalWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0;
    min-height: 100vh;
  }
  .header {
    position: relative;
    padding: 16px;
    border-bottom: 1px solid #f1f1f1;

    h2 {
      font-size: 32px;
      text-align: center;

      @include mobile-breakpoint {
        font-size: 17px;
      }
    }

    .closeButton {
      position: absolute;
      margin: 0;
      top: 22px;
      right: 16px;
      font-size: 12px;

      @include mobile-breakpoint {
        top: 16px;
      }
    }
  }
}
