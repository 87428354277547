@import "../../../../stylesheets/sass/partials/_responsive_mixins";

.atom-video-pause-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #F1F0EE;
    border-radius: 50%;

    :global(.visual-nav) & {
        bottom: 15px;
    }

    &:not(:hover) {
        transition: border-radius 0.3s ease-in;
        transition-delay: 0.3s;    
    }

    &:hover {
        border-radius: 25px;
    }
    
    & div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #1C1C1C;

        //Prevents flashing of animation on mobile devices
        @include desktop-breakpoint {
            &:hover {
                & span {
                    animation-name: show-copy;
                    padding-right: 12px;
                }
            }
        }
    }

    & svg {
        width: 16px;
        height: 16px;
        fill: black;
        padding: 12px 11px;
    }

    & span {
        opacity: 0;
        max-width: 0;
        height: 16px;
        animation-iteration-count: 1;
        animation-duration: 0.3s;
        animation-timing-function: ease-in;
        animation-fill-mode: forwards;
        display: flex;
        align-items: center;
        //Prevents an issue where hovering to the right of the collapsed button triggers the animation on loop
        pointer-events: none;
        position: absolute;
    }
}

// couldnt find a clean way to do this without JS that didn't show the animation on page load
// after the first mouseLeave event, .hover will be added to the button, and from then on the animation will be triggered on un-hover
.unhover span {
    animation-name: hide-copy;
}

@keyframes show-copy {
    0% {
        opacity: 0;
        max-width: 0;
    }
    50% {
        opacity: 0;
        max-width: 80px;
    }
    100% {
        opacity: 100;
        max-width: 80px;
    } 
}

@keyframes hide-copy {
    0% {
        opacity: 100;
        max-width: 80px;
        padding-right: 12px;
    }
    50% {
        opacity: 0;
        max-width: 80px;
    }
    100% {
        opacity: 0;
        max-width: 0;
    }
    
}
