@import "partials/_variables";

.fit-guarantee {
  padding: 16px;
  background-color: $color-light-teal-green;
}

.head {
  display: flex;
  margin-bottom: 4px;
}

.icon {
  margin-right: 8px;
}
