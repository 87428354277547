@import "partials/variables",
        "partials/mixins";

.atom-message-banner {

  &-container {
    display: flex;
    align-items: flex-start;
    padding: 20px 16px;
    gap: 8px;
    color: $color-content-primary;
    background-color: $color-background-secondary;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &-title {
    font-weight: bold;
  }

  // reason: class is referenced in file but doesn't have style attached currently
  // sass-lint:disable no-empty-rulesets
  &-icon {
    
  }

  // reason: class is referenced in file but doesn't have style attached currently
  // sass-lint:disable no-empty-rulesets
  &-text {
    
  }
}
