@import "partials/_variables";
@import "partials/_mixins";

.kifah-select {

  &-item-container {
    grid-area: main;
    display: flex;
    overflow: auto;
    flex-direction: column;
    padding: 40px 24px;
    background-color: $color-white;
    gap: 40px; 

    &.centered {
      justify-content: center;
    }
  }

  &-item-card {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px; 

    @include mobile-breakpoint {
      gap: 8px; 
    }

    .thumbnail-container {
      position: relative;
      width: 50%;
      height: 100%;

      .thumbnail {
        width: 100%;
        height: 100%;
      }

      .percent-off {
        position: absolute;
        bottom: 8px; 
        right: 8px;
        text-align: center;
        font-size: 11px;
        border-radius: 28px;
        padding: 2px 8px;
        line-height: 16px;
        background-color: $color-bag-kifah;
        border: none;
        font-weight: 600;
      }
    }

    .details-container {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;

      @include mobile-breakpoint {
        gap: 4px; 
      } 

      p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 20px;
      }

    }

    .cta {
      @include font-md;
      min-height: 50px;
      font-weight: 600;
      width: 100%;

      &.sale {
        color: $color-white;
        background-color: $color-black;
        border-color: $color-black;
      }

      &.sold {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-content-primary;
        background-color: $color-pearl;
        border-color: $color-pearl;
        font-weight: 500;

        .content {
          display: flex;
          height: 18px;
          align-items: center;
          gap: 4px;
          justify-content: center;
          color: $color-content-primary;
        }
      }
      
      .strike-copy {
        text-decoration: line-through;
        font-weight: 500;
      }
    }
  }

  &-cta {

    &-container {
      grid-area: footer;
      position: sticky;
      bottom: 0;
      padding: 10px 20px;
      padding-bottom: 20px;
      background: $color-white;
      box-shadow: 4px 4px 8px 6px rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 8px;
    }

    &-button {
      width: 100%;
      padding: 10px 20px;
      height: 50px;
      font-size: 17px;
      font-weight: 600;
      color: $color-black;
      background-color: $color-white;
      border: 1px solid $color-black;
    }
  }
}

.kifah-select-screen { 
  position: relative;
  display: grid;
  grid-template-areas: 
    "header"
    "main"
    "footer";
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  background-color: $color-white;
  
}
