.apple-pay-section-bag {
    apple-pay-button {
      --apple-pay-button-width: 100%;
      --apple-pay-button-height: 40px;
      --apple-pay-button-border-radius: 0;
      --apple-pay-button-padding: 5px 0;
    }
}

.or {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  text-align: center;

  &:before {
  @import "partials/_variables";
    background-color: $color-content-secondary;
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    top: 50%;
  }

  &:after {
    content: "OR";
    text-align: center;
    background-color: white;
    display: inline-block;
    position: relative;
    padding: 0 50px;
    font-size: 11px;
    color: #6F6B67;
    font-weight: 600;
    letter-spacing: 2px;
  }
}  