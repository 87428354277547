@import "partials/responsive_mixins";

.imageWrapper {
  margin: 32px 0 16px;

  @include mobile-breakpoint {
    margin: 30px 0 16px;
  }

  .imageBox {
    position: relative;
  }

  img {
    width: 104px;
    border-radius: 12px;
  }

  svg {
    position: absolute;
    top: -10px;
    right: -10px;
  }
}

.content {
  margin: 0 20px;
  padding-bottom: 24px;
  text-align: center;
  border-bottom: 1px solid $color-border-opaque;
}

.sizesTitle {
  margin: 40px 20px 24px;
  font-size: 24px;
  margin-bottom: 24px;

  @include mobile-breakpoint {
    font-size: 18px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-grow: 1;
  justify-content: space-between;

  .sizeList {
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    gap: 9px;
    margin-bottom: 40px;

    li {
      position: relative;
      list-style: none;

      label {
        margin-bottom: 0;
        padding: 10px 18px;
        border: 1px solid $color-content-primary;

        &:before {
          display: none;
        }
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }

      input:checked + label {
        background-color: $color-black;
        color: $color-white;
      }
    }
  }

  .editWrapper,
  .submitWrapper {
    padding: 16px 20px;
    border-top: 1px solid $color-border-opaque;
  }

  .editWrapper {
    border-bottom: 1px solid $color-border-opaque;
    display: flex;
    justify-content: space-between;

    button {
      width: 48%;
    }
  }
}

.snackBarError,
.snackBarSuccess {
  justify-content: center;
  color: $color-white;
  text-align: center;
}

.snackBarSuccess {
  background-color: $color-background-inverse;
}

.snackBarError {
  background-color: $color-error;
}
