@import "partials/_variables";
@import "partials/mixins";

.customer-promise-start-screen-title {
    margin-top: 48px;
    margin-left: 24px;
    margin-right: 24px;

    @media (max-width: $max-device-width-mobile) {
        h2 {
            font-size: 24px;
        }
    }

    hr {
        // modal width (530) - (modal margin (24) * 0.5)
        width: 528px;
        border-top: 1px solid $color-border-opaque;
        margin-left: -24px;
        border-bottom: none;
    }

    p {
        margin-bottom: 16px;
        font-size: 20pt;
        font-weight: 400;
    }

    .customer-promise-start-screen-imgs {
        margin: 40px 0;
        display: flex;
        overflow-x: auto;

        img {
          flex: 0 0 auto;
          height: 132px;
          width: 88px;
          border: 2px solid white;
        }
    }

    .customer-promise-start-screen-imgs-mobile {
        margin: 40px 0;
        display: flex;
        overflow-x: auto;

        img {
          flex: 0 0 auto;
          height: 280px;
          width: 187px;
          border: 2px solid white;
        }
    }

    .customer-promise-start-screen-buttons {
        position: static;
        bottom: 0;
        margin: 10px 0;
        display: flex;
        justify-content: center;
        gap: 10px;

        svg {
            cursor: pointer;
        }  
    }
}

.customer-promise-selection-screen {
    font-weight: 400;
    margin-top: 24px;
    margin-left: 24px;
    margin-right: 24px;
    height: 700px;

    @media (max-width: $max-device-width-mobile) {
        h2 {
            font-size: 24px;
        }
    }

    svg {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 16px;
        align-self: flex-end;
        margin-left: auto;
        cursor: pointer;
    }

    .customer-promise-selection-screen-imgs {
        img {
          height: 132px;
          width: 85px;
          border: 2px solid white;
          margin-bottom: 8px;
        }
    }

    .customer-promise-selection-screen-imgs-mobile {
        img {
          flex: 0 0 auto;
          height: 132px;
          width: 85px;
          border: 2px solid white;
          margin-bottom: 8px;
        }
    }

    .customer-promise-selection-screen-product {
        display: flex;
        flex-direction: row;
        align-items: center;

        .customer-promise-selection-screen-product-designer {
            display: flex;
            flex-direction: column;
            margin-left: 16px;
            font-weight: 600;
        }

        .customer-promise-selection-screen-product-name {
            margin-left: 16px;
        }

        .customer-promise-selection-screen-product-size {
            color: $color-secondary-copy;
            margin-left: 16px;
        }

        .customer-promise-selection-screen-product-checkbox {
            margin-left: auto;
            margin-right: 20px;
            margin-bottom: 40px;
            width: 20px;
            height: 20px;
            scale: 1.3;

            input {
                cursor: pointer;
            } 
        }
    }

    .customer-promise-selection-screen-button {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: sticky;
        bottom: 0;
        background-color: $color-background;
    }

    hr {
        position: absolute;
        border-top: 1px solid $color-border-opaque;
        width: 528px;

        @media (max-width: $max-device-width-mobile) {
            width: 120%;
        }
    }

    .primary-cta-next {
        height: 45px;
        width: 200px;
        margin: 24px 0;
        color: $color-white;
        background-color: $color-background-twelve;
        font-size: 0.9375rem;
        font-weight: 700;
        box-sizing: border-box;

        &[disabled] {
            background-color: $color-secondary-copy;
            pointer-events: none;
        }

        @media (max-width: $max-device-width-mobile) {
            margin-top: 20px;
            width: 100%;
        }
      }

      .sm-bag-status-message {
        background-color: #BA473B;
        color: white;
        width: 100%;
        position: sticky;
        top: 0;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 24px;
        margin-left: -24px;
        padding-right: 16px;
        bottom: 10px;
      }

      .customer-promise-selection-screen-hed {
        margin-bottom: 16px;
      }

      .customer-promise-selection-screen-dek {
        margin-bottom: 16px;     
      }
}

.customer-promise-end-screen {
    font-weight: 400;
    margin-top: 48px;
    margin-left: 24px;
    margin-right: 24px;
    height: 650px;

    @media (max-width: $max-device-width-mobile) {
        h2 {
            font-size: 24px;
        }
    }

    .customer-promise-end-screen-button {
        position: absolute;
        bottom: 0;
        width: 91%;
        padding-bottom: 24px;
        display: flex;
        justify-content: center;
        background-color: $color-background;

        @media (max-width: $max-device-width-mobile) {
            position: absolute;
            margin-top: 20px;
            padding-bottom: 20px;
            width: 100%;
        }
    }

    .button-hr {
        position: absolute;
        border-top: 1px solid $color-border-opaque;
        width: 528px;
    }

    .title-hr {
        position: absolute;
        width: 91%;
        border-top: 1px solid $color-border-opaque;
        border-left: none;
    }

    .primary-cta-next {
        height: 45px;
        width: 200px;
        margin-top: 24px;
        color: $color-white;
        background-color: $color-background-twelve;
        font-size: 0.9375rem;
        font-weight: 700;
        box-sizing: border-box;

        &[disabled] {
            background-color: $color-secondary-copy;
            pointer-events: none;
        }

        @media (max-width: $max-device-width-mobile) {
            margin-top: 20px;
            width: 90%;
            margin-left: -45px;
        }
    }

    .confidence-banner {
        padding: 16px 0;
        background-color: $color-light-teal-green;
        border-radius: 8px;
        text-align: left;
        width: 100%;
        height: auto;
        bottom: 0;

        &__dek {
            margin-left: 16px;
            font-weight: 100;
            margin-right: 16px;
            font-size: 9.4pt;
        }
    }

    .icon {
        margin-left: 16px;
        margin-right: 5px;
        display: flex;
        overflow-x: auto;
    }

    .customer-promise-end-screen-hed {
        margin-bottom: 24px;
        font-size: 24pt;
        text-align: center;
        font-weight: 300;
    }

    .customer-promise-end-screen-dek {
        margin-bottom: 24px;    
        text-align: center;
        font-weight: 100;
    }

    .customer-promise-end-screen-help {
        font-size: 14pt;
        font-weight: 200;
        margin-bottom: 24px;
        margin-top: 48px;
    }

    .customer-promise-end-screen-chat {
        margin-bottom: 24px;  
        font-weight: 100;
    }
}