@import "partials/_variables";
@import "partials/_mixins";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 37px;
  font-size: 16px;
  padding: 0 15px;
  margin-top: 8px;
}

.RENTALS {
  background-color: $color-border-opaque;
}

.rental-dates {
  text-align: right;
}

.PURCHASES {
  background-color: $color-border-opaque;
}

.SUBSCRIPTION {
  background-color: #CCBEB7;
}

.reserve-bag-group-header {
  padding: 22px 16px;
  display: flex;
  flex-direction: column;
  background-color: white;

  &-container {
    margin-top: 6px;
    display: flex;
    align-items: center;
  }

  &-shipping-text {
    color: $color-content-secondary;
  }

  &-dates {
    position: relative;
    line-height: 14px;

    &:nth-of-type(2n) {
      color: $color-content-secondary;
      margin-left: 8px;
      padding-left: 8px;
      border-left: 1px solid $color-content-secondary;
    }
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.bag-group {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #F2EFEE;
}

.bag-group-items {
  flex-grow: 1;
}

.bag-item {
  display: flex;
  border-bottom: 1px solid $border-gray-color;
  column-gap: 16px;
  background-color: white;
}

.display-name,
.retail-price {
  color: #6F6B67;
}

.price {
  font-weight: bold;
}

.product-image {
  width: 100%;
  max-height: 324px;
  object-fit: cover;
  position: absolute;

}

.product-image-container {
  max-width: 33%;
  max-height: 324px;
  flex: 1 0 33%;
  position: relative;
  aspect-ratio: 2/3;

  @include mobile-breakpoint {
    max-width: 48%;
    flex: 1 0 48%;
  }
}

.content-holder {
  flex-grow: 1;
  padding: 16px 16px 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-top {
    display: grid;
    grid-template-rows: 1fr 1fr;
    row-gap: 4px;

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.prices {
  flex: 1 0;
  margin-right: 30px;
}

.cross-out-price {
  font-weight: normal;
  margin-right: 4px;
  text-decoration: line-through;
  color: $color-content-gray-disabled;
}

.second-discount {
  color: $color-info-grey;
}

.promo-price {
  font-weight: bold;
  color: $color-content-positive;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;
  flex-grow: 0;
}

.size-container {
  display: flex;
  justify-content: space-between;
}

.subtotal-and-cta {
  background: white;
  box-shadow: 4px 4px 8px 6px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
}

.sticky-cta {
  position: sticky;
  bottom: 0;
}

.subtotal-holder {
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  background-color: white;
  border-bottom: 1px solid $border-gray-color;
}

.price-holder {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.subscription-purchase-image-holder {
  padding-left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholder-image {
  height: 90px;
  width: 33%;
  background-color: #F2EFEE;
  display: flex;
  justify-content: center;
  align-items: center;

  .plus-icon {
    pointer-events: none;
    width: 24px;
  }

  @include mobile-breakpoint {
    max-width: 48%;
    flex: 1 0 48%;
  }
}

.placeholder-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  :first-child {
    margin-bottom: 4px;
  }
  
}

.fit-gaurantee {
  margin: 10px 0;
}

.fineprint {
  font-size: 12px;
  color: $color-info-grey;
}

.cta {
  margin: 16px;
}

.delete-button {
  display: flex;
  padding: 16px;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.delete-button-svg {
  align-self: start;
  width: 26px;
  height: 26px;
  color: #6F6B67;
}

.subscription-bullet-points {
  margin-left: 20px;
  line-height: 1.25;
  color: $color-info-grey;
}

.final-sale {
  color: #C24F43;
  margin-left: 20px;
}
