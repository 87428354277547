@import "partials/_variables";
@import "partials/_mixins";

.breadcrumbs-inline {
    display: inline-block;
    padding: 16px;
    width: -webkit-fill-available;
    background-color: $color-gray-offwhite;

    @include mobile-and-tablet-breakpoint {
        background-color: $color-white;
    }
}