@import "partials/responsive_mixins";

.imageWrapper {
  display: flex;
  justify-content: center;

  .imageBox {
    position: relative;
  }

  img {
    width: 104px;
    border-radius: 12px;
  }

  svg {
    position: absolute;
    top: -10px;
    right: -10px;
  }
}
