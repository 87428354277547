@import "../../../../stylesheets/sass/partials/variables",
        "../../../../stylesheets/sass/partials/mixins";

.container {
  margin-top: 20px;
  height: 300px;
  position: relative;
  overflow: hidden;
  @include mobile-breakpoint {
    height: 250px;
  }
}

.shimmer-block {
  width: 100%;
  height: 65px;
  background-color: $color-gray-offwhite;
  margin-bottom: 13px;
}

.small-shimmer-block {
  width: 100%;
  height: 42px;
  background-color: $color-gray-offwhite;
  margin-bottom: 10px;
}

.tiny-shimmer-block {
  width: 55%;
  height: 25px;
  background-color: $color-gray-offwhite;
  margin-bottom: 10px;
}

.shimmer-animation {
  @include shimmer;
}

